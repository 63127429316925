import React from "react";

function SashayAway() {
    return <div className="jumbotron centered form-section first-section" id="main">
        <div className="container">
            <h2>Uh oh ☹️!</h2>
            <p>Trop tard! Ton prono n'a pas pu être pris en compte.</p>
        </div>
    </div>;
}

export default SashayAway;